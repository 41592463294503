import(/* webpackMode: "eager", webpackExports: ["KeycloakProvider"] */ "/vercel/path0/auth/provider/KeycloakProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["SocketProvider"] */ "/vercel/path0/auth/provider/SocketContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileOnlyView"] */ "/vercel/path0/components/mobile-only-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/progress/ClientWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/components/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApiProvider"] */ "/vercel/path0/lib/ApiProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
