'use client'

import { useEffect, useState } from 'react'
import Image from 'next/image'
import { Share2 } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { siteConfig } from '@/config/site'

interface MobileOnlyViewProps {
  fallback: React.ReactNode
}

export function MobileOnlyView({ fallback }: MobileOnlyViewProps) {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      const mobile = /mobile|iphone|ipad|android/.test(userAgent)
      setIsMobile(mobile)
    }

    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  const handleShare = async () => {
    try {
      await navigator.share({
        title: 'Web Version',
        url: window.location.href
      })
    } catch (error) {
      console.log('Error sharing:', error)
    }
  }

  if (!isMobile) return <>{fallback}</>

  return (
    <div className="fixed inset-0 from-primary to-primary-foreground flex flex-col items-start justify-center p-6 text-white h-screen">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-2">{siteConfig.name}</h1>
          <p className="text-xl">{siteConfig.description}</p>
        </div>
        
        <div className="bg-white/10 rounded-lg p-6 backdrop-blur-sm">
          <Image
            src="/mobile-preview.png?height=200&width=200"
            alt="Desktop Illustration"
            width={300}
            height={300}
            className="mx-auto mb-6"
          />
          <p className="text-center mb-6">
            For the best experience, please use {siteConfig.name} on a desktop or laptop computer.
          </p>
          <Button 
            className="w-full" 
            variant="secondary"
            onClick={handleShare}
          >
            <Share2 className="mr-2 h-4 w-4" />
            Share Desktop Link
          </Button>
        </div>

        <div className="text-center text-sm">
          &copy; {new Date().getFullYear()} {siteConfig.name}. All rights reserved.
        </div>
      </div>
    </div>
  )
}