export type SiteConfig = typeof siteConfig

export const siteConfig = {
  name: "Dream Emirates",
  description: ".....",
  mainNav: [],
  links: {
    twitter: "https://x.com/autonomi_ai",
    github: "https://github.com/autonomi-ai",
    docs: "https://docs.vlm.run/introduction",
  },
}
