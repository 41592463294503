"use client"

import * as React from "react"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"

import { Progress } from "@/components/ui/progress"

type Props = {
  initialValue: number
  style?: any
}
export default function ProgressDemo(props: Props) {
  const [progress, setProgress] = React.useState(props.initialValue)

  React.useEffect(() => {
    const timer = setInterval(() => setProgress((prev) => prev + 50 / prev), 10)

    // const timer = setTimeout(() => setProgress(66), 500)
    return () => clearInterval(timer)
  }, [])

  return (
    <div >
      <h4 style={{...props.style,top:'47%'}}>Dream Emirates</h4>
      <Progress style={props.style} value={progress} className="w-3/5" />
    </div>
  )
}
