"use client"

import React, { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"

import ProgressDemo from "./Progress"

interface ClientWrapperProps {
  children: React.ReactNode
}

export default function ClientWrapper({ children }: ClientWrapperProps) {
  const { loading, user } = useKeycloak()

  const divStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '300px',
    // padding: '20px'
  };

  return (
    <div className="relative flex min-h-screen flex-col">
      {loading  ? (
        <ProgressDemo style={divStyle} initialValue={1} />
      ) : (
        <div className="flex-1">{children}</div>
      )}
    </div>
  )
}
